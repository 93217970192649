.profile-container{
    height: 58vh;
    margin-left: 11px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: url('../../images/bck.jpg') ;
    background-color: transparent;

}

.profile-container-1{
    margin-left: 11px;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    position: relative;
    overflow-x: hidden;
    height: 94vh;
    background-color: #ffff;
}

.other-container{
    height: 100vh;
    border: 1px solid red;
    position: relative;
}


.my-image{
    height: 300px;
    width: 300px;
    border-radius: 50%;
    display: flex;
    position: relative;
    top: -15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../images/myimg.jpg') ;
    background-color: transparent;
    box-shadow: 0 -3px 17px 18px rgba(0,0,0,.25);

}

.experince-tag{
    position: relative;
    top: 47px;
    padding-right: 74px;
    width: 14%;
    left: 4%;

}

.skills-tag{
    border: 1px solid #c39a80;
    align-items: center;
    border-radius: 5px;
    margin: 10px 5px 10px 10px;
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    padding: 6px 5px;
    display: inline-table;
    height: 24px;
}

.skills-wala-container{
    width: 22%;
    position: relative;
    left: 14%;
    top: 27px;
}

.skill-heading{
    font-size: 25px;
    font-weight: 600;
}

.title-heading{
    font-size: 17px;
    font-weight: 600;
}

.project-link{
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    padding-top: 5px;
    font-size: 14px;
    margin-top: 10px;
}
