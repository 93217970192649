.backlog-container{
    height: 800px;
    width: 32%;
    box-shadow: 1px 1px 8px 1px #babaf3;
    border-radius: 10px;
    margin: 4px;
    padding: 5px;
    margin-left: 27px;
    font-size: 17px;
    font-weight: 500;
    display: table-row;
    
}