.size-14 {
    font-size: 16px;
    font-style: Poopins;
}

.left-container {
    display: block;
    width: 20vw;
    padding: 13px 5px;
    box-shadow: 0px 1px 7px;
    margin-left: 8px;
    margin-top: 14px;
    height: 90vh;
}

@media (max-width: 768px) {

    /* CSS rules for small screens */
    .my-image {
        top: -249px !important;
        right: 16px;
    }

    .profile-image-position {
        position: relative;
        left: 18vw !important;
        /* top: 332px !important; */

    }

    .summuray-container {
        position: absolute !important;
        left: 0px !important;
        margin-top: 51px;
    }

    .whole-resum-container {
        display: block !important;
    }

    .experince-tag {
        justify-self: flex-end;
        width: auto !important;
        height: auto;
        position: auto !important;
        top: auto !important;
        padding-right: 0px !important;
        margin-top: 40px;
    }

    .skills-wala-container {
        position: static !important;
        height: auto;
        margin-top: 0px;
        padding-left: 0px !important;
    }

    .project-list-container {
        margin-top: 50px !important;
    }

    .my-jounery-tag {
        margin-left: 0px !important;
        margin-top: 40px !important;
    }

    .boder-of-jurney-tag {
        width: 250px !important;
        height: 1px;
        border-top: 1px solid black;
        position: relative;
        left: 0px !important;
        /* padding-left: 10px; */
        margin-left: 66px;
    }
}

.text-of-populer-card{
    /* text-decoration: underline; */
    color: blue;
    font-size: 12px;
}

.heading-of-team-card{
    color: #423e3ed6;
    font-size: 15px;
    font-weight: bold;
}

.match-graph-container{
    position: fixed;
    top: 158px;
    left: 21%;
    margin: 10px 5px;
    height: 71%;
    width: 78%;
}

.phase-container{
    /* display: flex; */
    height: 800px;
    width: 22%;
    box-shadow: 1px 1px 8px 1px #babaf3;
    border-radius: 10px;
    margin: 4px;
    padding: 5px;
    margin-left: 27px;
}

.work-item-card{
    margin-bottom: 24px;
    padding-left: 11px;
    margin-left: 37px;
}

.wi-container{
    position: relative;
    top: 20px;
}

.work-item-card{
    border: 1px solid #0eb7e0;
    width: 200px;
    opacity: 1;
    font-size: 14px;
    /* font-weight: bold; */
    cursor: move;
    box-shadow: 3px 4px 3px 2px #c6dce2;
    border-radius: 10px;

}

.nav-container{
    width: 98%;
    height: 50px;
    position: sticky;
    top: 0px;
    padding: 4px;
    background: #e0e8e7;
    border-radius: 10px;
    border: 1px solid #d5e8e8;
    margin-bottom: 13px;
    box-shadow: 2px 6px 9px 2px #6e9d9d;
    margin-left: 22px;
    z-index: 3;
    margin-top: 10px;
}

.popup-container{
    border: 1px solid black;
    height: 200px;
    width: 200px;
}

.resend-btn-text{
    position: relative;
    float: right;
    text-decoration: underline;
    color: blue;
    right: 121px;
    bottom: 33px;
    cursor: pointer;
}

.resend-btn-text:hover{
    color: #0e4a58;
}